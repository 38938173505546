<template>
<v-app id="forgotpassword" class="deep-orange">
    <v-content>
        <v-container fluid fill-height>
            <v-layout align-center justify-center row wrap>
                <v-flex xs12 sm8 md4 lg4 class="login-box">
                    <v-card class="pa-3" height="250px">
                        <v-card-text>
                            <v-form class="pt-3" v-model="valid" ref="form">
                                <v-text-field append-icon="email" name="login" :label="$t('lang.enterEmail')" type="text" v-model="model.email" :rules="required"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-flex lg5 md5>
                                <v-btn block color="primary" @click="sendEmail" :loading="loading">{{$t('lang.submit')}}</v-btn>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex lg5 md5>
                                <v-btn block color="error" @click="$router.push('/login')">{{$t('lang.back')}}</v-btn>
                            </v-flex>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</v-app>
</template>

<script>
import { userLogin, sendEmmail } from '@/api/auth'

export default {
    data() {
        return {
            loading: false,
            valid: true,
            rememberMe: false,
            defaultLang: this.$i18n.locale,
            model: {
                email: '',
                language: this.$i18n.locale
            },
            required: [
                v => !!v || this.$t('lang.required'),
                (v) => /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || this.$t('lang.emailValidation')
            ]
        }
    },
    mounted() {
    },

    methods: {
        sendEmail() {
            const self = this;
            if (self.$refs.form.validate()) {
                self.model.redirectUrl = window.location.origin;
                self.model.type = 2
                self.loading = true
                sendEmmail(self.model).then(({ token, user }) => {
                    self.handleNotificationSuccessOrFainMsg(self.$t('lang.passwordLinkSent'), 'success');
                    self.$router.push('/login')
                }).catch((err) => self.handleServerSideValidationError(err))
            }
        }
    }
}
</script>

<style scoped>
  #forgotpassword {
    text-align: center;
    background-color: #e25303;
    background-image: url('/img/beige_gradient.jpg');
    width: 100% !important;
    height: 50% !important;
  }
</style>
